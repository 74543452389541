import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import styled from 'styled-components';
import SEO from '../components/seo';
import { device } from '../providers/theme';
import { handleTypeColor } from '../utils/typeColor';

const Container = styled.div`
  margin: 40px 0;
  padding: 0 32px;

  @media ${device.mobile} {
    padding: 0 12px;
  }

  a {
    text-decoration: underline;
  }
`;

const Message = styled.div`
  padding: 0 32px;

  @media ${device.mobile} {
    padding: 0 12px;
  }
`;

const NotFoundPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [currentTheme, setTheme] = useState(null);

  useEffect(() => {
    if (window.location.pathname.match('roedvin')) {
      window.location.replace('https://vinkammeret.dk/roedvin');
    }

    if (window.location.pathname.match('hvidvin')) {
      window.location.replace('https://vinkammeret.dk/hvidvin');
    }

    if (window.location.pathname.match('rose')) {
      window.location.replace('https://vinkammeret.dk/rose');
    }

    if (window.location.pathname.match('champagne')) {
      window.location.replace('https://vinkammeret.dk/champagne');
    }

    if (window.location.pathname.match('mousserende')) {
      window.location.replace('https://vinkammeret.dk/mousserende');
    }

    if (window.location.pathname.match('dessertvin')) {
      window.location.replace('https://vinkammeret.dk/dessertvin');
    }

    if (window.location.pathname.match('portvin')) {
      window.location.replace('https://vinkammeret.dk/portvin');
    }
    setLoading(false);
  }, []);

  return (
    <React.Fragment>
      <SEO
        title={'Siden blev ikke fundet'}
        description={'Øv! Siden blev ikke fundet'}
      />
      <Layout
        themeColor={handleTypeColor(currentTheme)}
        title="Siden blev ikke fundet"
        withoutSeo
      >
        <Container>
          {isLoading ? null : (
            <>
              <Message>
                <h1>Øv! Siden blev ikke fundet</h1>
                <Link to="/">Tilbage til forsiden</Link>
              </Message>
            </>
          )}
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default NotFoundPage;
